import Hummingbird from '@nextory/hummingbird'

export default ({ app: { $config, i18n } }, inject) => {
  const hummingbirdClient = new Hummingbird(
    $config.X_API_KEY,
    $config.X_API_SECRET,
    $config.API_BASE_URL,
    $config.USER_AGENT
  )

  hummingbirdClient.setLogLevel($config.CONSOLA_LEVEL)

  hummingbirdClient.setLanguage('EN') // FIXME Get it from i18n

  inject('hummingbird', hummingbirdClient)
}
