
import fullheight from '@nextory/components/mixins/fullheight'

import SideBar from '../components/SideBar.vue'
import TopNav from '../components/TopNav.vue'

export default {
  components: {
    SideBar,
    TopNav,
  },
  mixins: [fullheight],
  middleware: 'needToBeLogin',
  data() {
    return {
      heading: 'Create Ads',
      description: this.$t('topnav.description.create_ad_description'),
    }
  },
  methods: {
    selectedPage(params) {
      this.heading = params
    },

    selectedPageDescription(params) {
      this.description = params
    },
  },
}
