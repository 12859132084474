
import LoaderMiniSpin from './Utility/LoaderMiniSpin'
export default {
  components: { LoaderMiniSpin },
  props: {
    notification: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hours: '',
      copyNotification: this.notification,
      clearAll: false,
      shortListSize: 4,
      errorStatus: '',
    }
  },

  computed: {
    notificationMessage() {
      const n = this.notification.length
      if (n === 1) {
        return this.$t('notification.clear_notification')
      } else if (n < 5) {
        return `${this.$t('notification.clear_all')} ${n} ${this.$t(
          'notification.notifications'
        )}`
      } else {
        return `${this.$t('notification.load_next')} ${
          this.shortListSize
        } ${this.$t('notification.notifications')}`
      }
    },
  },

  watch: {
    notification(newVal) {
      this.copyNotification = newVal
    },
  },

  mounted() {
    this.hoursAgo()
  },
  methods: {
    showId(index) {
      this.copyNotification[index].status = this.$t('notification.read')
      this.copyNotification[index].closing = true
    },

    hoursAgo() {
      for (let i = 0; i < this.notification.length; i++) {
        const dateTimeString = this.notification[i].dateTime
        const dateObj = new Date(dateTimeString)
        const hours = dateObj.getHours()
        const minutes = dateObj.getMinutes()
        this.hours = `${hours}:${minutes} ` + this.$t('notification.hours_ago')
      }
    },
    getShortList(shortListSize) {
      return this.notification.slice(0, shortListSize)
    },
    removeNotification(idx) {
      Promise.all([
        this.$axios.post(`/notification/read?notificationIds=${idx}`),
        this.$axios.post(`/notification/clear?notificationIds=${idx}`),
      ])
        .then(([readResponse, clearResponse]) => {
          this.$emit('remove')
          this.errorStatus = ''
        })
        .catch(error => (this.errorStatus = error))
    },
    removeNotifications(shortListSize) {
      let clearNotificationList = this.copyNotification.slice(0, shortListSize)
      let idList = []
      for (let i = 0; i < clearNotificationList.length; i++) {
        idList.push(clearNotificationList[i].id)
      }
      this.clearAll = true

      this.$axios({
        method: 'post',
        url: `/notification/clear?notificationIds=${idList}`,
      })
        .then(response => {
          this.$emit('remove')
          this.clearAll = false
          this.errorStatus = ''
        })
        .catch(error => {
          this.errorStatus = error
          this.clearAll = false
        })
    },
  },
}
