
import Vue from 'vue'

import NotificationsPopup from './NotificationsPopup'

let handleOutsideClick

Vue.directive('closable', {
  bind(el, binding, vnode) {
    handleOutsideClick = e => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  },
})

export default {
  components: { NotificationsPopup },
  props: {
    pageHead: {
      type: String,
      required: true,
    },
    pageTitleDescription: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      is_expanded: false,
      is_notification: false,
      notificationPending: [],
      notificationError: '',
    }
  },

  mounted() {
    this.fetchNotification()
    setInterval(() => {
      this.fetchNotification()
    }, 15000)
  },

  methods: {
    fetchNotification() {
      this.$axios({
        method: 'get',
        url: `/notification/fetch?user=${this.$auth.user.email}`,
      })
        .then(response => {
          this.notificationPending = response.data.filter(
            e => e.status === 'UNREAD'
          )
        })
        .catch(error => (this.notificationError = error))
    },
    onClose() {
      this.is_notification = false
    },

    toggleNotification() {
      this.is_notification = !this.is_notification
    },

    removeNotification() {
      this.fetchNotification()
    },
    toggleMenu() {
      this.$nuxt.$emit('toggle-menu', this.is_expanded)
    },
  },
}
