export const AccountCountry = () => import('../../components/AccountCountry.vue' /* webpackChunkName: "components/account-country" */).then(c => wrapFunctional(c.default || c))
export const AdCreation = () => import('../../components/AdCreation.vue' /* webpackChunkName: "components/ad-creation" */).then(c => wrapFunctional(c.default || c))
export const AddText = () => import('../../components/AddText.vue' /* webpackChunkName: "components/add-text" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const CountryList = () => import('../../components/CountryList.vue' /* webpackChunkName: "components/country-list" */).then(c => wrapFunctional(c.default || c))
export const CreateTemplate = () => import('../../components/CreateTemplate.vue' /* webpackChunkName: "components/create-template" */).then(c => wrapFunctional(c.default || c))
export const DashBoard = () => import('../../components/DashBoard.vue' /* webpackChunkName: "components/dash-board" */).then(c => wrapFunctional(c.default || c))
export const FacebookAdSets = () => import('../../components/FacebookAdSets.vue' /* webpackChunkName: "components/facebook-ad-sets" */).then(c => wrapFunctional(c.default || c))
export const FacebookTemplate = () => import('../../components/FacebookTemplate.vue' /* webpackChunkName: "components/facebook-template" */).then(c => wrapFunctional(c.default || c))
export const FacebookVideoCreative = () => import('../../components/FacebookVideoCreative.vue' /* webpackChunkName: "components/facebook-video-creative" */).then(c => wrapFunctional(c.default || c))
export const ForgotPassword = () => import('../../components/ForgotPassword.vue' /* webpackChunkName: "components/forgot-password" */).then(c => wrapFunctional(c.default || c))
export const NotificationsPopup = () => import('../../components/NotificationsPopup.vue' /* webpackChunkName: "components/notifications-popup" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../components/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const TiktokAdSets = () => import('../../components/TiktokAdSets.vue' /* webpackChunkName: "components/tiktok-ad-sets" */).then(c => wrapFunctional(c.default || c))
export const TiktokAds = () => import('../../components/TiktokAds.vue' /* webpackChunkName: "components/tiktok-ads" */).then(c => wrapFunctional(c.default || c))
export const TiktokTemplate = () => import('../../components/TiktokTemplate.vue' /* webpackChunkName: "components/tiktok-template" */).then(c => wrapFunctional(c.default || c))
export const TiktokVideoCreative = () => import('../../components/TiktokVideoCreative.vue' /* webpackChunkName: "components/tiktok-video-creative" */).then(c => wrapFunctional(c.default || c))
export const TopNav = () => import('../../components/TopNav.vue' /* webpackChunkName: "components/top-nav" */).then(c => wrapFunctional(c.default || c))
export const UploadVideo = () => import('../../components/UploadVideo.vue' /* webpackChunkName: "components/upload-video" */).then(c => wrapFunctional(c.default || c))
export const VideoList = () => import('../../components/VideoList.vue' /* webpackChunkName: "components/video-list" */).then(c => wrapFunctional(c.default || c))
export const VideoTemplateList = () => import('../../components/VideoTemplateList.vue' /* webpackChunkName: "components/video-template-list" */).then(c => wrapFunctional(c.default || c))
export const VideoTemplateSettings = () => import('../../components/VideoTemplateSettings.vue' /* webpackChunkName: "components/video-template-settings" */).then(c => wrapFunctional(c.default || c))
export const UtilityLoaderMiniSpin = () => import('../../components/Utility/LoaderMiniSpin.vue' /* webpackChunkName: "components/utility-loader-mini-spin" */).then(c => wrapFunctional(c.default || c))
export const UtilityLoaderSpin = () => import('../../components/Utility/LoaderSpin.vue' /* webpackChunkName: "components/utility-loader-spin" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
