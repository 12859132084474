
import fullheight from '@nextory/components/mixins/fullheight'
import NxBg from '@nextory/components/src/NxBg.vue'

export default {
  components: {
    NxBg,
  },
  mixins: [fullheight],
  middleware: 'needToBeLogout',
}
