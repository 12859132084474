import { AxiosInstance } from 'axios'

/**
 * Custom connection scheme for Nextory
 */
export default class NxScheme {
  private http: AxiosInstance
  private $auth: any
  private readonly name: string
  private options: any

  constructor(auth: any, options: any) {
    this.name = options._name
    this.$auth = auth
    this.http = this.$auth.$storage.ctx.$axios
  }

  /**
   * Set Authorization in the axios header
   *
   * @internal Used by Nuxt Auth, don't rename.
   */
  _setToken(token: string) {
    // Set Authorization token for all axios requests
    this.http.defaults.headers.Authorization = token
  }

  /**
   * Remove Authorization in the axios header
   *
   * @internal Used by Nuxt Auth, don't rename.
   */
  _clearToken() {
    // Clear Authorization token for all axios requests
    this.http.defaults.headers.Authorization = ''
  }

  /**
   * Call on each connexion to the website
   * Set token & get user information
   */
  mounted() {
    // TODO: When we will have user information accessible from user token
    // We wiil be able to fect user and auto connect user
    return
  }

  /**
   * Login user
   * @param data: Credentials
   */
  async login({ data }: { data: any }) {
    // this.http.defaults.headers['BWWWIPI-DZSDSD'] = 'bsqwhsqh'
    return this.http
      .post('/login', data)
      .then(response => {
        const user = response.data
        this.setUserToken(user.accessToken)
        this.$auth.setUser(user)

        return user
      })
      .catch(e => {
        return Promise.reject(e)
      })
  }

  /**
   * Set token  to cache and axios header
   */
  setUserToken(token: string) {
    this.$auth.setToken(this.name, token)
    this._setToken(token)
  }

  /**
   * Get user information from /me/profile
   * Set new auth_token after
   */
  async fetchUser() {
    // TODO: When we will have user information accessible from user token
    return
  }

  /**
   * Reset auth cache/cookie and header
   */

  logout() {
    return this.$auth.reset()
  }

  reset() {
    this.$auth.setUser(false)
    this.$auth.setToken(this.name, false)
    this.$auth.setRefreshToken(this.name, false)

    return Promise.resolve()
  }
}
