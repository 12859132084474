const state = () => ({
  searchInfo: '',
  bookData: [],
  book: [],
  isLoaded: true,
  isError: true,
  errorDetail: {},
})

const getters = {
  searchByInfo(state) {
    return state.searchInfo
  },

  getBookData(state) {
    return state.bookData
  },

  isLoaded(state) {
    return state.isLoaded
  },
  isError(state) {
    return state.isError
  },

  errorDetail(state) {
    return state.errorDetail
  },

  getAdCreateBook(state) {
    return state.book
  },
}

const mutations = {
  SET_SEARCH_BY_INFO(state, searchInfo) {
    state.searchInfo = searchInfo
  },
  SET_BOOK_DATA(state, bookData) {
    state.bookData = bookData
  },
  SET_IS_LOADED(state, bool) {
    state.isLoaded = bool
  },
  SET_IS_ERROR(state, bool) {
    state.isError = bool
  },
  SET_ERRORDETAIL(state, errorDetail) {
    state.errorDetail = errorDetail
  },
  SET_AD_CREATE_BOOK(state, book) {
    state.book = book
  },
  // SET_RESET(state, reset) {
  //   state.reset = reset
  // },
}

const actions = {
  getBookInfo({ commit }, data) {
    commit('SET_IS_LOADED', false)
    commit('SET_IS_ERROR', false)
    return this.$axios
      .post(process.env.API_BASE_URL + '/dashBoard/retrieveProducts', data)
      .then(res => {
        commit('SET_BOOK_DATA', res.data)
        commit('SET_IS_LOADED', true)
        commit('SET_SEARCH_BY_INFO', data)
      })
      .catch(err => {
        commit('SET_IS_ERROR', true)
        if (err.response) {
          commit('SET_ERRORDETAIL', err.response.data)
        }
      })
  },
  getAdCreationInfo({ commit }, book) {
    commit('SET_AD_CREATE_BOOK', book)
  },
  resetSearch({ commit }) {
    commit('SET_BOOK_DATA', [])
    commit('SET_SEARCH_BY_INFO', '')
    commit('SET_IS_ERROR', false)
    commit('SET_IS_LOADED', true)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
