import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43a3a2e8 = () => interopDefault(import('../pages/adCreation.vue' /* webpackChunkName: "pages/adCreation" */))
const _794effcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _2b2eda48 = () => interopDefault(import('../pages/forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _12734da2 = () => interopDefault(import('../pages/signUp.vue' /* webpackChunkName: "pages/signUp" */))
const _7364f08e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _56499c1a = () => interopDefault(import('../pages/fb/template.vue' /* webpackChunkName: "pages/fb/template" */))
const _f4bf17cc = () => interopDefault(import('../pages/tik-tok/template.vue' /* webpackChunkName: "pages/tik-tok/template" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link--active',
  linkExactActiveClass: 'link--exact',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/en/adCreation",
    component: _43a3a2e8,
    name: "adCreation___en"
  }, {
    path: "/en/dashboard",
    component: _794effcc,
    name: "dashboard___en"
  }, {
    path: "/en/forgotPassword",
    component: _2b2eda48,
    name: "forgotPassword___en"
  }, {
    path: "/en/search",
    component: _3d268e12,
    name: "search___en"
  }, {
    path: "/en/signUp",
    component: _12734da2,
    name: "signUp___en"
  }, {
    path: "/en/users",
    component: _7364f08e,
    name: "users___en"
  }, {
    path: "/en/fb/template",
    component: _56499c1a,
    name: "fb-template___en"
  }, {
    path: "/en/tik-tok/template",
    component: _f4bf17cc,
    name: "tik-tok-template___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
