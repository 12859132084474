const state = () => ({
  allCountries: [],
})

const getters = {
  getAllCountries(state) {
    return state.allCountries
  },
}

const mutations = {
  SET_ALL_COUNTRIES(state, allCountries) {
    state.allCountries = allCountries
  },
}

const actions = {
  fetchAllCountries({ commit }) {
    return (
      this.$axios
        // TODO: Use correct endpoint when done, we don't have all countires with this one
        .get('/tikTok/getCountries')
        .then(res => {
          commit('SET_ALL_COUNTRIES', res.data)
        })
        .catch(e => {
          // TODO: Log and fallback
        })
    )
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
