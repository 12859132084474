
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      is_expanded: false,
      windowWidth: window.innerWidth,
      menuGroup: {
        CreateAds: {
          name: 'Create Ads',
          title: 'Create Ads',
          items: false,
          description: this.$t('topnav.description.create_ad_description'),
          route: '/search',
          adcreate: false,
        },
        Tiktok: {
          name: 'Tiktok',
          title: 'Tiktok template',
          items: false,
          description: this.$t('topnav.description.template_description'),
          route: '/tik-tok/template',
          channel: 'tiktok',
        },
        // Facebook: {
        //   name: 'Facebook',
        //   title: 'Facebook template',
        //   items: false,
        //   description: this.$t('topnav.description.template_description'),
        //   route: '/fb/template',
        //   channel: 'facebook',
        // },
      },
      menuRoute: '',
    }
  },

  watch: {
    is_expanded() {
      if (this.is_expanded) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },

    $route() {
      this.activeAdCreation()
    },
  },

  mounted() {
    this.activeAdCreation()
    window.onresize = () => {
      this.windowWidth = window.innerWidth
      if (window.innerWidth > 992) {
        this.is_expanded = false
      }
    }
  },

  created() {
    this.$nuxt.$on('toggle-menu', data => {
      this.is_expanded = data
      this.toggleMenu()
    })
  },

  methods: {
    activeAdCreation() {
      if (this.$route.path.includes('adCreation')) {
        this.menuGroup.CreateAds.adcreate = true
      } else {
        this.menuGroup.CreateAds.adcreate = false
      }
    },
    ...mapMutations([
      'SET_BOOK_DATA',
      'SET_SEARCH_BY_INFO',
      'SET_IS_ERROR',
      'SET_IS_LOADED',
    ]),
    toggleMenu() {
      if (this.windowWidth > 992) {
        this.is_expanded = false
      } else {
        this.is_expanded = !this.is_expanded
      }
    },
    pageLoad(title, items, channel, description) {
      this.$emit('page-selected', title)
      this.$emit('page-description', description)
      this.toggleMenu()
    },

    logout() {
      this.SET_BOOK_DATA([])
      this.SET_SEARCH_BY_INFO('')
      this.SET_IS_ERROR(false)
      this.SET_IS_LOADED(true)
      this.$auth.logout().then(() => {
        this.$router.push(this.localePath('/'))
      })
    },
  },
}
